<template>
  <div>
    <div class="col" style="padding-bottom: 2rem">
      <h2>A</h2>
      <a href="https://canadaindiafoundation.com/" target="_blank">
        <img
          alt="Canada India Foundation"
          src="../assets/cif_logo.jpg"
          height="100"
        />
      </a>
      <h2 style="padding: 1rem">Initiative</h2>
    </div>
    <img alt="We for Canada India" src="@/assets/logo.png" height="250" />
    <h1>We For Canada India</h1>
    <h3>Website Coming Soon</h3>
    <h1>Join on our mobile app</h1>
    <div style="padding: 1rem" />
    <div :class="!isMobile ? 'row' : 'col'">
      <div>
        <a
          href="https://apps.apple.com/ca/app/we-for-canada-india/id6480280262"
        >
          <img
            alt="Download on the App Store"
            src="@/assets/appstore.svg"
            height="80"
          />
        </a>
      </div>
      <div style="padding: 1rem" />
      <div>
        <a
          href="https://play.google.com/store/apps/details?id=com.weforcanadaindia.app"
        >
          <img
            alt="Get it on Google Play"
            src="@/assets/playstore.svg"
            height="80"
          />
        </a>
      </div>
    </div>
    <div style="padding-top: 100px" :class="!isMobile ? 'row' : 'col'">
      <p style="padding: 10px">Questions? Email us at:</p>
      <a href="mailto:contact@weforcanadaindia.com" style="color: blue">
        <div>contact@weforcanadaindia.com</div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data: () => ({
    isMobile: false,
  }),
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
